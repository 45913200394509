import { UserSpeakUpResponseData, speakUpApiHandler } from '@api/speakUpApiHandler';
import { ApiResponse, SpeakUpInterface } from '@interfaces/index';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useSpeakUpResponses = (speakUpId: number, enabled: boolean) =>
  useQuery<ApiResponse<SpeakUpInterface>>({
    queryKey: ['speakUpConversationHistory', speakUpId],
    queryFn: () => speakUpApiHandler.getSpeakUpResponses(speakUpId),
    enabled,
  });

export const useSaveInstantHelpSpeakUp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: speakUpApiHandler.saveInstantHelpSpeakUpResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['speakUpResponses'] });
    },
  });
};

export const useSaveSpeakUpConversation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: speakUpApiHandler.saveSpeakUpConversationResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['speakUpConversations'] });
    },
  });
};

export const useGetUserSpeakUps = (
  responded: boolean | null,
  assessmentId: number | null,
  questionnaireId: number | null,
  archived: boolean | null,
  enabled: boolean,
) =>
  useQuery<ApiResponse<UserSpeakUpResponseData[]>>({
    queryKey: ['userSpeakUps', responded, assessmentId, questionnaireId],
    queryFn: () =>
      speakUpApiHandler.getUserSpeakUps({ responded, assessmentId, questionnaireId, archived }),
    enabled,
  });

export const useSetSpeakUpResponsesSeen = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: speakUpApiHandler.setSpeakUpResponsesSeen,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updatedSpeakUpSeenResponses'] });
    },
  });
};

export const useArchiveSpeakUpResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: speakUpApiHandler.archiveSpeakUpResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archivedSpeakUpSeenResponse'] });
    },
  });
};
