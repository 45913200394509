import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RefreshConfigurationState {
  userSpeakUpDataRefresh: boolean;
  jobTitlesDataRefresh: boolean;
  jobTypesDataRefresh: boolean;
  teamsDataRefresh: boolean;
  locationsDataRefresh: boolean;
}

const initialState: RefreshConfigurationState = {
  userSpeakUpDataRefresh: false,
  jobTitlesDataRefresh: false,
  jobTypesDataRefresh: false,
  teamsDataRefresh: false,
  locationsDataRefresh: false,
};

const refreshConfigurationSlice = createSlice({
  name: 'refreshConfigurations',
  initialState,
  reducers: {
    setRefreshSpeakUps: (state, action: PayloadAction<boolean>) => ({
      ...state,
      userSpeakUpDataRefresh: action.payload,
    }),
    resetConfigurations: () => initialState,
  },
});

export const refreshConfigurationSliceActions = refreshConfigurationSlice.actions;

export default refreshConfigurationSlice;
