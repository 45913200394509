import { getToken } from '@api/config';
import { createHeaders } from '@api/httpHeaderConfig';
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

// Create Axios instance with base configuration
const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ACTION_PLAN_API_URL,
  timeout: 120000,
  headers: createHeaders(),
});

// Request interceptor for dynamic token injection
apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }

    return config;
  },
  (error) => Promise.reject(error.response?.data ?? error.message),
);

// Response interceptor for extracting data and handling errors
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  // (error) => Promise.reject(error.response?.data ?? error.message),
  (error) => error.response?.data,
);

export default apiClient;
