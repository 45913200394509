import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';

interface ReusableDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const DialogComponent: React.FC<ReusableDialogProps> = ({
  open,
  onClose,
  title,
  children,
  actions = null,
  maxWidth = 'sm',
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth={maxWidth}
    fullWidth
    sx={{ zIndex: 999999999, borderRadius: '20px' }}
  >
    <DialogTitle>
      {title}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
);

DialogComponent.defaultProps = {
  actions: null,
  maxWidth: 'sm',
};

export default DialogComponent;
