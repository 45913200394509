import { UserRoles } from 'custom.d';

export const getUserRoleLabel = (role: string) => {
  switch (role) {
    case UserRoles.USER:
      return 'User';
    case UserRoles.ADMIN:
      return 'Admin';
    case UserRoles.SUPER_ADMIN:
      return 'Super Admin';
    case UserRoles.ADVISOR:
      return 'Advisor';
    default:
      return '';
  }
};

export const getUserSeniorityLabel = (seniority: string) => {
  switch (seniority) {
    case 'junior':
      return 'Junior';
    case 'mid_level':
      return 'Mid-Level';
    case 'senior':
      return 'Senior';
    case 'c_level':
      return 'C-Level';
    default:
      return '';
  }
};

export const getUserGenderLabel = (gender: string) => {
  switch (gender) {
    case 'male':
      return 'Male';
    case 'female':
      return 'Female';
    case 'non_binary':
      return 'Non-binary';
    default:
      return '';
  }
};

export const smoothScrollTo = (targetPosition: number, duration: number) => {
  const startPosition = window.scrollY || window.pageYOffset;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  function scrollStep() {
    const currentTime = 'now' in window.performance ? performance.now() : new Date().getTime();
    const elapsedTime = currentTime - startTime;
    const scrollProgress = Math.min(1, elapsedTime / duration);

    window.scrollTo(0, startPosition + (targetPosition - startPosition) * scrollProgress);

    if (scrollProgress < 1) {
      requestAnimationFrame(scrollStep);
    }
  }

  requestAnimationFrame(scrollStep);
};

export const scrollToSpecificSectionById = (id: string) => {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 0);
};
