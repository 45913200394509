import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import InstantThoughMessaging from '@components/ModalContent/InstantThoughMessaging';
import ViewCommentModal from '@components/ModalContent/ViewComment';
import { truncateString } from '@utils/string.util';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import chatIcon from '../../assets/images/earlyWarning/chat-icon.svg';
import styles from './index.module.scss';

const NewHomeThoughtsAndSpeakupCard: React.FunctionComponent<ActionsCardProps> = (props) => {
  const {
    commentTitle,
    focusArea,
    focusAreaColor,
    action,
    questionnaireName,
    score,
    response,
    responseTitle,
    thoughtId,
    updateResponseAction,
    speakupResponseId,
    isSpeakup,
    disabledSeen,
    isResponseAvailable,
  } = props;

  const [openViewCommentsModal, setOpenViewCommentsModal] = useState(false);

  const styleClasses = classNames([
    styles.hsActionsCard,
    focusAreaColor === '#BEAAF3' && styles.calmLilac,
    focusAreaColor === '#019DF2' && styles.skyBlue,
    focusAreaColor === '#FCD300' && styles.sunYellow,
    focusAreaColor === '#8EEA36' && styles.zingyGreen,
    focusAreaColor === '#06BF97' && styles.aquaGreen,
    focusAreaColor === '#FF8A4A' && styles.warmOrange,
  ]);

  const handleUpdateResponse = async () => {
    if (isSpeakup) {
      updateResponseAction(thoughtId);
    } else {
      updateResponseAction(thoughtId);
    }
  };

  const handleOpenViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(true);
  }, []);

  const handleCloseViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(false);
  }, []);

  return (
    <div className={styleClasses}>
      <div className={styles.hsActionsCardTitle}>
        <p className={styles.cardQuestName}>{questionnaireName}</p>
        {isSpeakup ? (
          <div className={styles.speakupContainer}>
            <div className={styles.isSpeakupText}>Speak up</div>
            <Img src={chatIcon} alt="Chat Icon" className={styles.chatIcon} />
          </div>
        ) : (
          <p>
            {focusArea} <span>{score}</span>
          </p>
        )}
      </div>
      <div className={`${styles.hsActionsCardContent} ${styles.cardFontSettings}`}>
        <p className={styles.mainTitleX}>{commentTitle}</p>‘{truncateString(action, 280)}’
      </div>
      <div className={styles.cardCommentSectionX}>
        {isResponseAvailable ? (
          <>
            <h2 className={styles.mainTitleX}>{responseTitle}</h2>
            <p className={styles.cardResponseSection}>{truncateString(response, 80)}</p>
          </>
        ) : (
          <>
            <h2 className={styles.mainTitleX}>Awaiting response</h2>
            <p className={styles.cardResponseSection}>
              Your feedback has been received and awaiting response from your organisation.
            </p>
          </>
        )}
      </div>
      <div className={styles.hsActionsCardTitle}>
        <p />
        <div className={styles.hsButtonContainer}>
          <DefaultButton variant="secondary" onClick={handleOpenViewCommentModal}>
            {!isSpeakup ? 'View Response' : 'View & Message'}
          </DefaultButton>
          {!disabledSeen && (
            <DefaultButton variant="darken" onClick={handleUpdateResponse}>
              Seen & archive
            </DefaultButton>
          )}
        </div>
      </div>

      {isSpeakup ? (
        <InstantThoughMessaging
          title={questionnaireName}
          isOpen={openViewCommentsModal}
          onClose={handleCloseViewCommentModal}
          speakUpId={thoughtId}
          isUserInterface
        />
      ) : (
        <ViewCommentModal
          title={questionnaireName}
          isOpen={openViewCommentsModal}
          onClose={handleCloseViewCommentModal}
        >
          {response && response.split('\n').join('<br/>')}
        </ViewCommentModal>
      )}
    </div>
  );
};

NewHomeThoughtsAndSpeakupCard.defaultProps = {
  disabledSeen: false,
  isResponseAvailable: true,
};

type ActionsCardProps = {
  commentTitle: string;
  focusArea: string;
  focusAreaColor: any;
  action: string;
  questionnaireName: string;
  score: number;
  response: string;
  responseTitle: string;
  thoughtId: number;
  updateResponseAction: (thoughtId: number) => void;
  speakupResponseId: number;
  isSpeakup: boolean;
  disabledSeen?: boolean;
  isResponseAvailable?: boolean;
};

export default NewHomeThoughtsAndSpeakupCard;
