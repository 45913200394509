import { getQuestionnairesNames } from '@api/questionnaires';
import Checkbox from '@components/Atoms/Checkbox';
import DefaultButton from '@components/Atoms/DefaultButton';
import Modal from '@components/Atoms/Modal';
import SelectComponent from '@components/Atoms/SelectComponent';
import { isEmptyValidation, noValidation } from '@helpers/validation.helper';
import { useSaveInstantHelpSpeakUp } from '@hooks/api/useSpeakUp';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useInput, { getCheckedValue, getInputValue } from '@hooks/useInput';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { SPEAK_UP_MESSAGING_ERRORS } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { getInstantHelpData } from 'store/Actions/InstantHelpAction';
import { refreshConfigurationSliceActions } from 'store/Slices/RefreshCofigurationsSlice';
import styles from './index.module.scss';

const CreateSpeakUp: React.FunctionComponent<CreateSpeakUpProps> = (props) => {
  const { loading, createSpeakUpOpen, handleSpeakUpModalOpen } = props;
  const history = useHistory();
  const mutation = useSaveInstantHelpSpeakUp();
  const [questionnaireNames, setQuestionnaireNames] = useState<
    { value: number; label: string }[] | []
  >([]);
  const [selectedQuestionnaireName, setSelectedQuestionnaireName] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: 'No questionnaire selected' });
  const [submitted, setSubmitted] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const instantHelpData = useAppSelector((state: RootState) => state.instantHelp);
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const { isDesktop } = useWindowDimensions();
  const [isSharingThought, setIsSharingThought] = useState<boolean>(false);
  const [isCreateSpeakUpLimitExceeded, setIsCreateSpeakUpLimitExceeded] = useState(false);

  const {
    value: descriptionValue,
    isValid: descriptionIsValid,
    hasError: descriptionHasError,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: descriptionReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: shareIdentityValue = false,
    isValid: shareIdentityIsValid,
    hasError: shareIdentityHasError,
    valueChangeHandler: shareIdentityChangeHandler,
    inputBlurHandler: shareIdentityBlurHandler,
    reset: shareIdentityReset,
  } = useInput(noValidation, (value) => value, false);

  const checkboxChangeHandler = (event: any) => {
    const newValue = getCheckedValue(event);
    shareIdentityChangeHandler(newValue);
  };

  useEffect(() => {
    if (createSpeakUpOpen) {
      getQueNames();
      dispatch(refreshConfigurationSliceActions.setRefreshSpeakUps(false));
    }
  }, [createSpeakUpOpen]);

  const getQueNames = async () => {
    const res = await getQuestionnairesNames();
    if (res === 401) {
      setQuestionnaireNames([]);
    } else {
      const resData = res.map((item: { id: number; name: string }) => ({
        value: item.id,
        label: item.name,
      }));
      setQuestionnaireNames(resData);
    }
  };

  const handleQuestionnaireChange = async (value: any) => {
    setSelectedQuestionnaireName(value);
  };

  useEffect(() => {
    async function getInstantData() {
      await dispatch(getInstantHelpData());
    }

    if (!instantHelpData.isLoaded) {
      getInstantData();
    }
  }, [dispatch, instantHelpData.isLoaded]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (descriptionIsValid && selectedQuestionnaireName?.value) {
      setIsSharingThought(true);

      await mutation.mutateAsync(
        {
          assessmentId: null,
          message: descriptionValue,
          shareMyIdentity: shareIdentityValue,
          questionnaireId: selectedQuestionnaireName?.value,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              setIsSharingThought(false);
              dispatch(refreshConfigurationSliceActions.setRefreshSpeakUps(true));
              toast.success('Your message has been successfully shared');
              closeModal();
            } else if (
              dt?.message?.toLowerCase() ===
              SPEAK_UP_MESSAGING_ERRORS.YOU_HAVE_EXCEEDED_YOUR_CREATE_SPEAK_UP_LIMIT
            ) {
              setIsCreateSpeakUpLimitExceeded(true);
              toast.error(dt?.message || 'An unexpected error occurred. Try again later');
              setIsSharingThought(false);
            } else {
              setIsCreateSpeakUpLimitExceeded(false);
              toast.error('An unexpected error occurred. Try again later');
              setIsSharingThought(false);
            }
          },

          onError: (err) => {
            if (
              err?.message?.toLowerCase() ===
              SPEAK_UP_MESSAGING_ERRORS.YOU_HAVE_EXCEEDED_YOUR_CREATE_SPEAK_UP_LIMIT
            ) {
              toast.error(
                'You have sent high number of speak up messages recently. Please wait for the administrator to respond to you to be able to follow up. If you need urgent help, please click here to access urgent help resources.',
              );
              setIsSharingThought(false);
            } else {
              toast.error('An unexpected error occurred. Try again later');
              setIsSharingThought(false);
            }
          },
        },
      );
    } else {
      toast.error('Questionnaire and Description are required');
    }
  };

  const closeModal = () => {
    handleSpeakUpModalOpen();
    setSubmitted(false);
    descriptionReset();
    shareIdentityReset();
    setSelectedQuestionnaireName({ value: 0, label: 'No questionnaire selected' });
    setIsSharingThought(false);
    setIsCreateSpeakUpLimitExceeded(false);
  };

  return (
    <Modal
      open={createSpeakUpOpen}
      setOpen={handleSpeakUpModalOpen}
      onTapBackgroundClose
      className={styles.modalRoot}
      overlay
    >
      <DefaultButton
        color="primary"
        type="button"
        className={styles.btnClose}
        onClick={() => closeModal()}
      >
        <div className={styles.btnCloseLabel}>Close</div>
        <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
      </DefaultButton>

      <div className={styles.helpAndSpeakupContainer}>
        <>
          <div className={styles.hsBoxContainer}>
            <div className={styles.speakUpBox}>
              {isCreateSpeakUpLimitExceeded ? (
                <div className={styles.speakUpLimitExceedContainer}>
                  <div className={styles.speakUpBoxTopContainer}>
                    <div className={styles.titleContainer}>
                      <span className={styles.title}>Speak Up</span>
                    </div>
                  </div>
                  <div className={styles.responseLimitExceededContainer}>
                    <p>
                      You have sent high number of speak up messages recently. Please wait for the
                      administrator to respond to you to be able to follow up. If you need urgent
                      help, please{' '}
                      <span
                        onClick={() => {
                          closeModal();
                          history.push('/wellbeing');
                        }}
                      >
                        click here
                      </span>{' '}
                      to access urgent help resources.
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {!submitted ? (
                    <>
                      <div className={styles.speakUpBoxTopContainer}>
                        <div className={styles.titleContainer}>
                          <span className={styles.title}>Speak Up</span>
                        </div>
                        {/* <div className={styles.chatIcon} /> */}
                      </div>
                      <p className={styles.subHeadingForSelect}>
                        Which area would you like to speak up about?
                      </p>
                      <div className={styles.select}>
                        <SelectComponent
                          id="questionnaireName"
                          name="questionnaireName"
                          value={questionnaireNames.find(
                            (option) => option.value === selectedQuestionnaireName.value,
                          )}
                          placeholder="Select area"
                          onChange={(value) => handleQuestionnaireChange(value)}
                          options={questionnaireNames}
                          className={styles.selectComponent}
                        />
                      </div>
                      <div className={styles.formSection}>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <textarea
                              name="description"
                              rows={5}
                              value={descriptionValue}
                              onChange={descriptionChangeHandler}
                              onBlur={descriptionBlurHandler}
                              placeholder="What’s on your mind?"
                              className={descriptionHasError ? 'is-invalid' : styles.textArea}
                            />
                            {descriptionHasError && <div className="invalid-feedback" />}
                          </div>
                          <div className={styles.middleDescriptionContainer}>
                            <span className={styles.middleDescription}>
                              {shareIdentityValue ? (
                                <>
                                  <span>Your name and email address will be shared</span> alongside
                                  your thoughts. If you’d like to keep your thoughts anonymous,
                                  untick the box below.
                                </>
                              ) : (
                                <>
                                  Your thoughts will be shared <span>anonymously</span>. However, if
                                  you’d like them to know it’s you, tick the box below.
                                </>
                              )}
                            </span>
                          </div>

                          <div className={styles.checkContainer}>
                            <Checkbox
                              id="shareIdentity"
                              name="shareIdentity"
                              checked={shareIdentityValue}
                              onChange={checkboxChangeHandler}
                              className={styles.roundedCheckbox}
                            />
                            <label className={styles.checkBoxTitle} htmlFor="shareIdentity">
                              Share my identity
                            </label>
                          </div>

                          <div className={styles.hsGetHelpButton}>
                            <DefaultButton
                              color="primary"
                              type="submit"
                              disabled={
                                !descriptionIsValid ||
                                isSharingThought ||
                                selectedQuestionnaireName.value === 0
                              }
                              className={styles.defaultButton}
                            >
                              {isSharingThought ? (
                                <>
                                  <CircularProgress size={20} color="inherit" /> &nbsp;Sharing...
                                </>
                              ) : (
                                'Share thoughts'
                              )}
                            </DefaultButton>
                          </div>
                        </form>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.speakUpBoxTopContainer2}>
                        <div className={styles.titleContainer}>
                          <span className={styles.title}>Speak Up</span>
                        </div>
                      </div>

                      <div className={styles.expressDescriptionContainer}>
                        <p className={styles.expressDescription1}>
                          Thank you for expressing your thoughts and feelings with your
                          organisation. We have shared your thoughts with them.
                        </p>
                      </div>

                      <div className={styles.checkBoxWrapper}>
                        {' '}
                        <svg
                          className={styles.checkmark}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 52 52"
                        >
                          {' '}
                          <circle
                            className={styles.checkmarkCircle}
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                          />{' '}
                          <path
                            className={styles.checkmarkCheck}
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                          />
                        </svg>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
};

CreateSpeakUp.defaultProps = {
  loading: false,
};

interface CreateSpeakUpProps extends React.InputHTMLAttributes<HTMLInputElement> {
  loading?: boolean;
  createSpeakUpOpen: boolean;
  handleSpeakUpModalOpen: any;
}

export default CreateSpeakUp;
