import blueTray from '@assets/images/icons/blueTray.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import ColoredBox from '@components/ColoredBox';
import NewHomeThoughtsAndSpeakupCard from '@components/ThoughtsAndSpeakupCard/newHomepage';
import { useAppSelector } from '@hooks/useAppSelector';
import useEnableCreateSpeakUp from '@hooks/useEnableCreateSpeakUp';
import { formatDateStringSplitDots } from '@utils/date.util';
import React, { useState } from 'react';
import { RootState } from 'store';
import CreateSpeakUp from '../CreateSpeakUp';
import styles from './index.module.scss';
import SpeakUpPortal from '../SpeakUpPortal';

const ThoughtsResponses: React.FunctionComponent<ThoughtsResponsesProps> = ({
  loading,
  allResponses,
  handleUpdateResponse,
  handleSeenAndArchive,
}) => {
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig.data);
  const createSpeakUpEnabled = companyConfigData?.showSpeakUp;
  const [createSpeakUpOpen, setCreateSpeakUpOpen] = useState(false);

  return (
    <>
      {createSpeakUpEnabled && <SpeakUpPortal />}

      {allResponses?.length > 0 ? (
        <div className={styles.responsesSection}>
          <div className={styles.topHeader}>
            <h6>{`My Responses (${allResponses?.length})`}</h6>
          </div>

          <section id="responses">
            <ColoredBox title="" color="blue" dataLength={allResponses?.length} isHomePage={false}>
              <div className={styles.responsesRoot}>
                <div className={styles.responseFlexContainer}>
                  {allResponses?.length > 0 ? (
                    allResponses.map((action: any, index: number) => (
                      <div key={`${index}'thoughtsCard`} className={`${styles.responseCardRoot}`}>
                        <NewHomeThoughtsAndSpeakupCard
                          key={index}
                          commentTitle={
                            action?.isSpeakup
                              ? `Your comment on ${formatDateStringSplitDots(
                                  action?.speakUpCreatedDate,
                                )}`
                              : 'Your comment'
                          }
                          focusArea={action?.isSpeakup ? 'Speak up' : action?.title}
                          questionnaireName={
                            action?.isSpeakup ? action?.questionnaire : action?.questionnaireName
                          }
                          focusAreaColor={action.isSpeakup ? '#019DF2' : action.color}
                          action={action?.isSpeakup ? action?.speakUpMessage : action?.thoughtsText}
                          score={action?.isSpeakup ? 0 : action?.score}
                          response={
                            action?.isSpeakup
                              ? action?.response?.responseMessage || ''
                              : action?.response?.responseMessage
                          }
                          responseTitle={
                            action?.isSpeakup
                              ? `Response on ${formatDateStringSplitDots(
                                  action?.response?.responseCreated,
                                )}`
                              : 'Response'
                          }
                          thoughtId={action?.isSpeakup ? action?.speakUpId : action?.thoughtId}
                          updateResponseAction={
                            action.isSpeakup ? handleSeenAndArchive : handleUpdateResponse
                          }
                          speakupResponseId={action?.response?.responseId || 0}
                          isSpeakup={action?.isSpeakup}
                          isResponseAvailable={action?.response}
                        />
                      </div>
                    ))
                  ) : (
                    <div className={styles.noResponseContainer}>
                      <Img src={blueTray} className={styles.blueTray} alt="blue tray" />
                      <p className={styles.noResponseText}>
                        You haven’t had any responses from your employer
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </ColoredBox>
          </section>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

ThoughtsResponses.defaultProps = {
  loading: false,
};

interface ThoughtsResponsesProps {
  loading?: boolean;
  allResponses: any;
  handleUpdateResponse: (thoughtId: number) => void;
  handleSeenAndArchive: (responseId: number) => void;
}

export default ThoughtsResponses;
