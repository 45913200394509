import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0, // Retry failed requests once
      refetchOnWindowFocus: false,
      // staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    },
  },
});

export default queryClient;
