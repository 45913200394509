/* eslint-disable prettier/prettier */
import { ApiResponse, ErrorDetail, UserDetailsByUniqueIdInterface } from '@interfaces/index';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getUserById = async (id: number) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

/**
 * Delete a user
 */
export const deleteUser = async (userId: number) => {
  const requestOptions = {
    method: 'DELETE',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, requestOptions);
};

/**
 * Update a user
 */
export const updateUser = async (userId: number, data: any) => {
  const requestOptions = {
    method: 'PUT',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, requestOptions);
};

/**
 * Update a user Terms and condition agreement
 */
export const updateUserTerms = async (userId: number, data: any) => {
  const requestOptions = {
    method: 'PUT',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/agreeTerms/${userId}`, requestOptions);
};

/**
 * Add a user
 */
export const insertUser = async (body: any) => {
  const requestOptions = {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users`, requestOptions);
};

export const registerUser = async (body: any) => {
  const requestOptions = {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/sign-up`, requestOptions);
};

export const completeRegisterUser = async (userId: number, body: any) => {
  const requestOptions = {
    method: 'PUT',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(body),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/complete-sign-up/${userId}`,
    requestOptions,
  );

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(errorResponse.message);
  }

  return response.json();
};

export const isUserOnBoarded = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/show-onboarding`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const addUserOneSignalId = async (oneSignalInfo: any) => {
  const requestOptions = {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify({ oneSignalInfo }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/save-os-details`,
    requestOptions,
  );

  const result: any = await response.json();

  return result;
};

export const getUserByUniqueId = async (uniqueId: string) => {
  const requestOptions = {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify({ uniqueId }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-by-unique-id`,
    requestOptions,
  );

  const responseData: ApiResponse<UserDetailsByUniqueIdInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};
