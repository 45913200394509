import { CommonCompanyItemsInterface, adminUsersApiHandler } from '@api/adminUsersApiHandler';
import { ApiResponse } from '@interfaces/index';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useGetJobTitles = (enabled: boolean) =>
  useQuery<ApiResponse<CommonCompanyItemsInterface[]>>({
    queryKey: ['companyJobTitles'],
    queryFn: () => adminUsersApiHandler.getJobTitles(),
    enabled,
  });

export const useSaveJobTitle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.saveJobTitle,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['savedJobTitles'] });
    },
  });
};

export const useUpdateJobTitle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.updateJobTitle,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updatedJobTitles'] });
    },
  });
};

export const useDeleteJobTitle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.deleteJobTitle,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deletedJobTitles'] });
    },
  });
};

export const useGetJobTypes = (enabled: boolean) =>
  useQuery<ApiResponse<CommonCompanyItemsInterface[]>>({
    queryKey: ['companyJobTypes'],
    queryFn: () => adminUsersApiHandler.getJobTypes(),
    enabled,
  });

export const useSaveJobType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.saveJobType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['savedJobTypes'] });
    },
  });
};

export const useUpdateJobType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.updateJobType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updatedJobTypes'] });
    },
  });
};

export const useDeleteJobType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.deleteJobType,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deletedJobTypes'] });
    },
  });
};

export const useGetJobLocations = (enabled: boolean) =>
  useQuery<ApiResponse<CommonCompanyItemsInterface[]>>({
    queryKey: ['companyJobLocations'],
    queryFn: () => adminUsersApiHandler.getJobLocations(),
    enabled,
  });

export const useSaveJobLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.saveJobLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['savedJobLocations'] });
    },
  });
};

export const useUpdateJobLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.updateJobLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updatedJobLocations'] });
    },
  });
};

export const useDeleteJobLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.deleteJobLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deletedJobLocations'] });
    },
  });
};

export const useGetJobTeams = (enabled: boolean) =>
  useQuery<ApiResponse<CommonCompanyItemsInterface[]>>({
    queryKey: ['companyJobTeams'],
    queryFn: () => adminUsersApiHandler.getJobTeams(),
    enabled,
  });

export const useSaveJobTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.saveJobTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['savedJobTeams'] });
    },
  });
};

export const useUpdateJobTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.updateJobTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updatedJobTeams'] });
    },
  });
};

export const useDeleteJobTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: adminUsersApiHandler.deleteJobTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deletedJobTeams'] });
    },
  });
};
