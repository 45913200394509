import { getUserById } from '@api/users';
import DefaultButton from '@components/Atoms/DefaultButton';
import Modal from '@components/Atoms/Modal';
import FormFieldsUser from '@components/FormFieldsUser';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  useGetJobLocations,
  useGetJobTeams,
  useGetJobTitles,
  useGetJobTypes,
  useSaveJobLocation,
  useSaveJobTeam,
  useSaveJobTitle,
  useSaveJobType,
} from '@hooks/api/useAdminUsers';
import classNames from 'classnames';
import format from 'date-fns/format';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  SelectableJobOptionType,
  SelectableOptionType,
  UserFieldValues,
  UserRoles,
  UserRolesList,
} from '../../../custom.d';
import styles from './index.module.scss';

const defaultFormValues = {
  email: '',
  surname: '',
  forename: '',
  seniority: 'non_specified',
  sex: 'non_specified',
  ethnicity: null,
  payLevel: 0,
  jobType: null,
  jobTitle: null,
  team: null,
  location: null,
  dob: null,
  joined: null,
  peopleLead: false,
  isAdmin: false,
  speakupAlertEligibility: false,
  userRole: 'user',
} as UserFieldValues;

const EditEmployee: React.FunctionComponent<EditEmployeeProps> = (props) => {
  const { title, isOpen, onClose, onSave, userId } = props;
  const [loading, setLoading] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [loadingJobTypes, setLoadingJobTypes] = useState(false);
  const [errorJobTitles, setErrorJobTitles] = useState(false);
  const [errorTeams, setErrorTeams] = useState(false);
  const [errorLocations, setErrorLocations] = useState(false);
  const [errorJobType, setErrorJobType] = useState(false);
  const [error, setError] = useState(false);
  const [teams, setTeams] = useState<Array<SelectableJobOptionType>>([]);
  const [locations, setLocations] = useState<Array<SelectableJobOptionType>>([]);
  const [jobTitles, setJobTitles] = useState<Array<SelectableJobOptionType>>([]);
  const [jobTypes, setJobTypes] = useState<Array<SelectableJobOptionType>>([]);
  const [selectedUserRole, setSelectedUserRole] = useState<SelectableOptionType>(UserRolesList[0]);

  const today = new Date();

  const saveJobTitle = useSaveJobTitle();
  const saveJobType = useSaveJobType();
  const saveJobTeam = useSaveJobTeam();
  const saveJobLocation = useSaveJobLocation();

  const validationSchema = yup.object({
    surname: yup.string().required(),
    forename: yup.string().required(),
    email: yup.string().email().required(),
    jobType: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number().integer(),
        lookupId: yup.number().integer(),
      })
      .nullable(true),
    jobTitle: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number().integer(),
        lookupId: yup.number().integer(),
      })
      .nullable(true),
    team: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number().integer(),
        lookupId: yup.number().integer(),
      })
      .nullable(true),
    location: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number().integer(),
        lookupId: yup.number().integer(),
      })
      .nullable(true),
    seniority: yup.string(),
    sex: yup.string(),
    ethnicity: yup.string().nullable(true),
    payLevel: yup.number().integer().min(0),
    peopleLead: yup.boolean(),
    isAdmin: yup.boolean().required().default(false),
    dob: yup.date().max(today, 'DOB must be in the past').nullable(true),
    joined: yup.date().max(today, 'Joined must be in the past').nullable(true),
    speakupAlertEligibility: yup.boolean().default(false),
    userRole: yup.string().required().default('user'),
  });

  const methods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
  } = methods;

  const isAdmin = watch('isAdmin');
  /**
   * Create a new team option and set the value
   */
  const handleAddTeam = React.useCallback(
    async (name: string) => {
      try {
        setLoadingTeams(true);

        await saveJobTeam.mutateAsync(
          {
            name,
          },
          {
            onSuccess: (dt) => {
              if (dt?.success) {
                handleRefetchJobOptionItems('teams');
                const newOptions = [
                  ...teams,
                  {
                    label: name,
                    value: dt?.responseObject?.teamId,
                    lookupId: dt?.responseObject?.id,
                  },
                ] as Array<SelectableJobOptionType>;
                setTeams(newOptions);
                methods.setValue('team', {
                  label: name,
                  value: dt?.responseObject?.teamId,
                  lookupId: dt?.responseObject?.id,
                });
                setLoadingTeams(false);
                toast.success(`Created new team '${name}'`);
              } else {
                setLoadingTeams(false);
                toast(`Error creating team '${name}'`);
              }
            },

            onError: (err) => {
              setErrorTeams(true);
              setLoadingTeams(false);
              toast.error(err?.message || `Error creating team '${name}'`);
            },
          },
        );
      } catch (err) {
        setErrorTeams(true);
        setLoadingTeams(false);
        toast(`Unable to save team '${name}`);
      }
    },
    [teams, methods],
  );

  const handleAddLocation = React.useCallback(
    async (name: string) => {
      try {
        setLoadingLocations(true);

        await saveJobLocation.mutateAsync(
          {
            name,
          },
          {
            onSuccess: (dt) => {
              if (dt?.success) {
                handleRefetchJobOptionItems('locations');
                const newOptions = [
                  ...teams,
                  {
                    label: name,
                    value: dt?.responseObject?.locationId,
                    lookupId: dt?.responseObject?.id,
                  },
                ] as Array<SelectableJobOptionType>;
                setLocations(newOptions);
                methods.setValue('location', {
                  label: name,
                  value: dt?.responseObject?.locationId,
                  lookupId: dt?.responseObject?.id,
                });
                setLoadingLocations(false);
                toast.success(`Created new location '${name}'`);
              } else {
                setLoadingLocations(false);
                toast(`Error creating location '${name}'`);
              }
            },

            onError: (err) => {
              setErrorLocations(false);
              setLoadingLocations(false);
              toast.error(err?.message || `Error creating location '${name}'`);
            },
          },
        );
      } catch (err) {
        setErrorLocations(true);
        setLoadingLocations(false);
        toast(`Unable to save location '${name}`);
      }
    },
    [methods, locations],
  );

  const handleAddJobTitle = React.useCallback(
    async (name: string) => {
      try {
        setLoadingJobTitles(true);

        await saveJobTitle.mutateAsync(
          {
            title: name,
          },
          {
            onSuccess: (dt) => {
              if (dt?.success) {
                handleRefetchJobOptionItems('jobTitles');
                const newOptions = [
                  ...teams,
                  {
                    label: name,
                    value: dt?.responseObject?.jobTitleId,
                    lookupId: dt?.responseObject?.id,
                  },
                ] as Array<SelectableJobOptionType>;
                setJobTitles(newOptions);
                methods.setValue('jobTitle', {
                  label: name,
                  value: dt?.responseObject?.jobTitleId,
                  lookupId: dt?.responseObject?.id,
                });
                setLoadingJobTitles(false);
                toast.success(`Created new job title '${name}'`);
              } else {
                setLoadingJobTitles(false);
                toast(`Error creating job title '${name}'`);
              }
            },

            onError: (err) => {
              setErrorJobTitles(false);
              setLoadingJobTitles(false);
              toast.error(err?.message || `Error creating job title '${name}'`);
            },
          },
        );
      } catch (err) {
        setErrorJobTitles(true);
        setLoadingJobTitles(false);
        toast(`Unable to save job title '${name}'`);
      }
    },
    [methods, jobTitles],
  );

  const handleAddJobType = React.useCallback(
    async (name: string) => {
      try {
        setLoadingJobTypes(true);

        await saveJobType.mutateAsync(
          {
            type: name,
          },
          {
            onSuccess: (dt) => {
              if (dt?.success) {
                handleRefetchJobOptionItems('jobTypes');
                const newOptions = [
                  ...teams,
                  {
                    label: name,
                    value: dt?.responseObject?.jobTypeId,
                    lookupId: dt?.responseObject?.id,
                  },
                ] as Array<SelectableJobOptionType>;
                setJobTypes(newOptions);
                methods.setValue('jobType', {
                  label: name,
                  value: dt?.responseObject?.jobTypeId,
                  lookupId: dt?.responseObject?.id,
                });
                setLoadingJobTypes(false);
                toast.success(`Created new job type '${name}'`);
              } else {
                setLoadingJobTypes(false);
                toast.error(`Error creating job type '${name}'`);
              }
            },

            onError: (err) => {
              setErrorJobType(false);
              setLoadingJobTypes(false);
              toast.error(err?.message || `Error creating job type '${name}'`);
            },
          },
        );
      } catch (err) {
        setErrorJobType(true);
        setLoadingJobTypes(false);
        toast(`Unable to save job type '${name}`);
      }
    },
    [methods, jobTypes],
  );

  const onSubmit = useCallback(
    (data: any, event: any) => {
      event.preventDefault();

      const mutableData = data;

      if (
        methods.getValues('userRole') === UserRoles.USER ||
        methods.getValues('userRole') === UserRoles.ADVISOR
      ) {
        mutableData.speakupAlertEligibility = false;
        mutableData.isAdmin = false;
      }

      if (
        methods.getValues('userRole') === UserRoles.ADMIN ||
        methods.getValues('userRole') === UserRoles.SUPER_ADMIN
      ) {
        mutableData.isAdmin = true;
      }

      onSave(mutableData, methods);
    },
    [methods, onSave],
  );

  const handleClose = useCallback(() => {
    methods.reset();
    onClose();
  }, [methods, onSave]);

  const {
    data: titlesResponses,
    error: titlesResponsesError,
    isLoading: isTitlesResponsesLoading,
    refetch: refetchTitles,
  } = useGetJobTitles(isOpen);

  const {
    data: typesResponses,
    error: typesResponsesError,
    isLoading: isTypesResponsesLoading,
    refetch: refetchTypes,
  } = useGetJobTypes(isOpen);

  const {
    data: locationsResponses,
    error: locationsResponsesError,
    isLoading: isLocationsResponsesLoading,
    refetch: refetchLocations,
  } = useGetJobLocations(isOpen);

  const {
    data: teamsResponses,
    error: teamsResponsesError,
    isLoading: isTeamsResponsesLoading,
    refetch: refetchTeams,
  } = useGetJobTeams(isOpen);

  useEffect(() => {
    setLoading(
      isTitlesResponsesLoading ||
        isTypesResponsesLoading ||
        isLocationsResponsesLoading ||
        isTeamsResponsesLoading,
    );

    if (
      titlesResponsesError ||
      typesResponsesError ||
      locationsResponsesError ||
      teamsResponsesError
    ) {
      setError(true);
      setLoading(false);
    }
  }, [
    isTitlesResponsesLoading,
    isTypesResponsesLoading,
    isLocationsResponsesLoading,
    isTeamsResponsesLoading,
    titlesResponsesError,
    typesResponsesError,
    locationsResponsesError,
    teamsResponsesError,
  ]);

  useEffect(() => {
    if (titlesResponses?.success) {
      const transformedData = titlesResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.id,
        lookupId: dt.lookUpId,
      }));
      setJobTitles(transformedData);
    } else {
      setJobTitles([]);
    }
  }, [titlesResponses, isOpen]);

  useEffect(() => {
    if (typesResponses?.success) {
      const transformedData = typesResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.id,
        lookupId: dt.lookUpId,
      }));
      setJobTypes(transformedData);
    } else {
      setJobTypes([]);
    }
  }, [typesResponses, isOpen]);

  useEffect(() => {
    if (locationsResponses?.success) {
      const transformedData = locationsResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.id,
        lookupId: dt.lookUpId,
      }));
      setLocations(transformedData);
    } else {
      setLocations([]);
    }
  }, [locationsResponses, isOpen]);

  useEffect(() => {
    if (teamsResponses?.success) {
      const transformedData = teamsResponses.responseObject.map((dt) => ({
        label: dt.name,
        value: dt.id,
        lookupId: dt.lookUpId,
      }));
      setTeams(transformedData);
    } else {
      setTeams([]);
    }
  }, [teamsResponses, isOpen]);

  const handleRefetchJobOptionItems = (type: string) => {
    switch (type) {
      case 'jobTitles':
        refetchTitles();
        break;

      case 'jobTypes':
        refetchTypes();
        break;

      case 'locations':
        refetchLocations();
        break;

      case 'teams':
        refetchTeams();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const getData = async () => {
      const userResponse = await getUserById(userId);

      userResponse.dob = userResponse.dob ? format(new Date(userResponse.dob), 'yyyy-MM-dd') : null;
      userResponse.joined = userResponse.joined
        ? format(new Date(userResponse.joined), 'yyyy-MM-dd')
        : null;
      setSelectedUserRole(
        UserRolesList.find((role) => role.value === userResponse.role) || UserRolesList[0],
      );

      userResponse.userRole =
        UserRolesList.find((role) => role.value === userResponse.role)?.value ||
        UserRolesList[0].value;

      reset(userResponse);

      if (userResponse && userResponse?.jobType) {
        methods.setValue('jobType', {
          label: userResponse?.jobType?.label,
          value: userResponse?.jobType?.value,
          lookupId: userResponse?.jobType?.value,
        });
      }

      if (userResponse && userResponse?.jobTitle) {
        methods.setValue('jobTitle', {
          label: userResponse?.jobTitle?.label,
          value: userResponse?.jobTitle?.value,
          lookupId: userResponse?.jobTitle?.value,
        });
      }

      if (userResponse && userResponse?.location) {
        methods.setValue('location', {
          label: userResponse?.location?.label,
          value: userResponse?.location?.value,
          lookupId: userResponse?.location?.value,
        });
      }

      if (userResponse && userResponse?.team) {
        methods.setValue('team', {
          label: userResponse?.team?.label,
          value: userResponse?.team?.value,
          lookupId: userResponse?.team?.value,
        });
      }
    };

    try {
      getData();
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }, [userId, reset]);

  return (
    <div>
      <div className={styles.overlay}>
        <Modal
          open={isOpen}
          setOpen={handleClose}
          onTapBackgroundClose
          className={styles.modalRoot}
        >
          <div className={styles.modalHeader}>
            <h4>{title}</h4>
            <button type="button" className={styles.btnClose} onClick={handleClose}>
              <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
            </button>

            <hr />
          </div>

          <div className={styles.statusRoot}>
            {loading && !error && (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only" />
              </div>
            )}
            {error && <p className={styles.error}>Error fetching data</p>}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!error && !loading && (
              <FormFieldsUser
                isEdit
                loading={loading}
                loadingTeams={loadingTeams}
                loadingLocations={loadingLocations}
                loadingJobTitles={loadingJobTitles}
                loadingJobTypes={loadingJobTypes}
                onAddLocation={handleAddLocation}
                onAddJobTitle={handleAddJobTitle}
                onAddJobType={handleAddJobType}
                onAddTeam={handleAddTeam}
                control={control}
                register={register}
                errors={errors}
                teams={teams}
                locations={locations}
                jobTitles={jobTitles}
                jobTypes={jobTypes}
                getValues={watch}
                setValue={setValue}
                selectedUserRole={selectedUserRole}
                handleRefetchJobOptionItems={handleRefetchJobOptionItems}
              />
            )}

            <hr className={styles.hrLine} />
            <div className={styles.footerContainer}>
              <DefaultButton disabled={loading} type="submit" variant="primary">
                Confirm
              </DefaultButton>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

interface EditEmployeeProps {
  title: string;
  userId: number;
  isOpen: boolean;
  onClose: () => void | undefined;
  onSave: (data: any, methods: any) => Promise<void> | undefined;
}

export default EditEmployee;
