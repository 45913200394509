import { ApiResponse } from '@interfaces/index';
import apiClient from './configs/httpHandler';

export const adminUsersApiHandler = {
  // JOB TITLES
  getJobTitles: async (): Promise<ApiResponse<CommonCompanyItemsInterface[]>> =>
    apiClient.get(`/get-job-titles`),
  saveJobTitle: async (
    data: Partial<{ title: string }>,
  ): Promise<ApiResponse<JobTitleCreationResponseInterface>> =>
    apiClient.post('/add-job-title', data),
  updateJobTitle: async (
    data: Partial<{ jobTitleId: number; newTitle: string }>,
  ): Promise<ApiResponse<JobTitleUpdatedResponseInterface>> =>
    apiClient.put('/update-job-title', data),
  deleteJobTitle: async (
    data: Partial<{ jobTitleId: number }>,
  ): Promise<ApiResponse<CommonDeletionResponseInterface>> =>
    apiClient.delete('/delete-job-title', { data }),

  // JOB TYPES
  getJobTypes: async (): Promise<ApiResponse<CommonCompanyItemsInterface[]>> =>
    apiClient.get(`/get-job-types`),
  saveJobType: async (
    data: Partial<{ type: string }>,
  ): Promise<ApiResponse<JobTypeCreationResponseInterface>> =>
    apiClient.post('/add-job-type', data),
  updateJobType: async (
    data: Partial<{ jobTypeId: number; newType: string }>,
  ): Promise<ApiResponse<JobTypeUpdatedResponseInterface>> =>
    apiClient.put('/update-job-type', data),
  deleteJobType: async (
    data: Partial<{ jobTypeId: number }>,
  ): Promise<ApiResponse<CommonDeletionResponseInterface>> =>
    apiClient.delete('/delete-job-type', { data }),

  // JOB LOCATIONS
  getJobLocations: async (): Promise<ApiResponse<CommonCompanyItemsInterface[]>> =>
    apiClient.get(`/get-locations`),
  saveJobLocation: async (
    data: Partial<{ name: string }>,
  ): Promise<ApiResponse<JobLocationCreationResponseInterface>> =>
    apiClient.post('/add-location', data),
  updateJobLocation: async (
    data: Partial<{ locationId: number; newName: string }>,
  ): Promise<ApiResponse<JobLocationUpdatedResponseInterface>> =>
    apiClient.put('/update-location', data),
  deleteJobLocation: async (
    data: Partial<{ locationId: number }>,
  ): Promise<ApiResponse<CommonDeletionResponseInterface>> =>
    apiClient.delete('/delete-location', { data }),

  // JOB TEAMS
  getJobTeams: async (): Promise<ApiResponse<CommonCompanyItemsInterface[]>> =>
    apiClient.get(`/get-teams`),
  saveJobTeam: async (
    data: Partial<{ name: string }>,
  ): Promise<ApiResponse<JobTeamCreationResponseInterface>> => apiClient.post('/add-team', data),
  updateJobTeam: async (
    data: Partial<{ teamId: number; newName: string }>,
  ): Promise<ApiResponse<JobTeamUpdatedResponseInterface>> => apiClient.post('/update-team', data),
  deleteJobTeam: async (
    data: Partial<{ teamId: number }>,
  ): Promise<ApiResponse<CommonDeletionResponseInterface>> =>
    apiClient.delete('/delete-team', { data }),
};

export interface CommonCompanyItemsInterface {
  id: number;
  name: string;
  lookUpId: number;
}

export interface JobTitleCreationResponseInterface {
  id: number;
  name: string;
  companyId: number;
  jobTitleId: number;
  createdAt: string;
  updatedAt: string;
}

export interface JobTitleUpdatedResponseInterface {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface JobTypeCreationResponseInterface {
  id: number;
  name: string;
  companyId: number;
  jobTypeId: number;
  createdAt: string;
  updatedAt: string;
}

export interface JobTypeUpdatedResponseInterface {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface JobLocationCreationResponseInterface {
  id: number;
  name: string;
  companyId: number;
  locationId: number;
  createdAt: string;
  updatedAt: string;
}

export interface JobTeamUpdatedResponseInterface {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface JobTeamCreationResponseInterface {
  id: number;
  name: string;
  companyId: number;
  teamId: number;
  createdAt: string;
  updatedAt: string;
}

export interface JobLocationUpdatedResponseInterface {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface CommonDeletionResponseInterface {
  id: number;
  lookupId: number;
  deleted: boolean;
}
