import { Delete } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import DefaultButton from '@components/Atoms/DefaultButton';
import DialogComponent from '@components/Atoms/DialogComponent';
import { scrollToSpecificSectionById } from '@helpers/general.helpers';
import {
  useDeleteJobLocation,
  useDeleteJobTeam,
  useDeleteJobTitle,
  useDeleteJobType,
  useSaveJobLocation,
  useSaveJobTeam,
  useSaveJobTitle,
  useSaveJobType,
  useUpdateJobLocation,
  useUpdateJobTeam,
  useUpdateJobTitle,
  useUpdateJobType,
} from '@hooks/api/useAdminUsers';
import { SelectableJobOptionType } from 'custom.d';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

interface ManageAdminFieldsModalProps {
  actions: {
    open: boolean;
    type: 'jobTypes' | 'jobTitles' | 'teams' | 'locations' | null;
    name: string | null;
  };
  handleActions: (
    open: boolean,
    type: 'jobTypes' | 'jobTitles' | 'teams' | 'locations' | null,
    name: string | null,
  ) => void;
  teams: Array<SelectableJobOptionType>;
  locations: Array<SelectableJobOptionType>;
  jobTitles: Array<SelectableJobOptionType>;
  jobTypes: Array<SelectableJobOptionType>;

  handleRefetchJobOptionItems: (type: string) => void;
}

const ManageAdminFieldsModal: React.FC<ManageAdminFieldsModalProps> = ({
  actions,
  handleActions,
  teams,
  locations,
  jobTitles,
  jobTypes,
  handleRefetchJobOptionItems,
}) => {
  const [data, setData] = useState<SelectableJobOptionType[]>([]);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editName, setEditName] = useState<string>('');
  const [newItem, setNewItem] = useState<string>('');
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const dialogBoxRef = useRef<HTMLDivElement | null>(null);

  const saveJobTitle = useSaveJobTitle();
  const saveJobType = useSaveJobType();
  const saveJobTeam = useSaveJobTeam();
  const saveJobLocation = useSaveJobLocation();

  const updateJobTitle = useUpdateJobTitle();
  const updateJobType = useUpdateJobType();
  const updateJobTeam = useUpdateJobTeam();
  const updateJobLocation = useUpdateJobLocation();

  const deleteJobTitle = useDeleteJobTitle();
  const deleteJobType = useDeleteJobType();
  const deleteJobTeam = useDeleteJobTeam();
  const deleteJobLocation = useDeleteJobLocation();

  useEffect(() => {
    if (actions.type) {
      handleSetData();
    }
  }, [actions, teams, locations, jobTitles, jobTypes]);

  const handleSetData = () => {
    if (actions.type === 'jobTitles') {
      setData(jobTitles || []);
    }

    if (actions.type === 'jobTypes') {
      setData(jobTypes || []);
    }

    if (actions.type === 'locations') {
      setData(locations || []);
    }

    if (actions.type === 'teams') {
      setData(teams || []);
    }
  };

  const closeModal = () => {
    handleActions(false, null, null);
    setIsAdding(false);
    setEditName('');
    setNewItem('');
    setEditingId(null);
    setData([]);
  };

  const handleEdit = (id: number, name: string) => {
    setIsAdding(false);
    setEditingId(id);
    setEditName(name);
  };

  const handleSave = async (id: number) => {
    if (actions.type === 'jobTitles') {
      await updateJobTitle.mutateAsync(
        {
          jobTitleId: id,
          newTitle: editName,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('jobTitles');
              toast.success('Job title updated successfully');
              setData(
                data.map((item) => (item.value === id ? { ...item, label: editName } : item)),
              );
              setEditingId(null);
            } else {
              setEditingId(null);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setEditingId(null);
          },
        },
      );
    }

    if (actions.type === 'jobTypes') {
      await updateJobType.mutateAsync(
        {
          jobTypeId: id,
          newType: editName,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('jobTypes');
              toast.success('Job type updated successfully');
              setData(
                data.map((item) => (item.value === id ? { ...item, label: editName } : item)),
              );
              setEditingId(null);
            } else {
              setEditingId(null);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setEditingId(null);
          },
        },
      );
    }

    if (actions.type === 'teams') {
      await updateJobTeam.mutateAsync(
        {
          teamId: id,
          newName: editName,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('teams');
              toast.success('Team updated successfully');
              setData(
                data.map((item) => (item.value === id ? { ...item, label: editName } : item)),
              );
              setEditingId(null);
            } else {
              setEditingId(null);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setEditingId(null);
          },
        },
      );
    }

    if (actions.type === 'locations') {
      await updateJobLocation.mutateAsync(
        {
          locationId: id,
          newName: editName,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('locations');
              toast.success('Location updated successfully');
              setData(
                data.map((item) => (item.value === id ? { ...item, label: editName } : item)),
              );
              setEditingId(null);
            } else {
              setEditingId(null);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setEditingId(null);
          },
        },
      );
    }
  };

  const handleDelete = async (id: number) => {
    if (actions.type === 'jobTitles') {
      await deleteJobTitle.mutateAsync(
        {
          jobTitleId: id,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('jobTitles');
              toast.success('Job title deleted successfully');
              setData(data.filter((item) => item.value !== id));
            } else {
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
          },
        },
      );
    }

    if (actions.type === 'jobTypes') {
      await deleteJobType.mutateAsync(
        {
          jobTypeId: id,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('jobTypes');
              toast.success('Job type deleted successfully');
              setData(data.filter((item) => item.value !== id));
            } else {
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
          },
        },
      );
    }

    if (actions.type === 'teams') {
      await deleteJobTeam.mutateAsync(
        {
          teamId: id,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('jobTypes');
              toast.success('Team deleted successfully');
              setData(data.filter((item) => item.value !== id));
            } else {
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
          },
        },
      );
    }

    if (actions.type === 'locations') {
      await deleteJobLocation.mutateAsync(
        {
          locationId: id,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('locations');
              toast.success('Location deleted successfully');
              setData(data.filter((item) => item.value !== id));
            } else {
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
          },
        },
      );
    }
  };

  const handleAdd = async () => {
    if (actions.type === 'jobTitles') {
      await saveJobTitle.mutateAsync(
        {
          title: newItem,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('jobTitles');
              toast.success('Job title added successfully');
              setNewItem('');
              setIsAdding(false);
            } else {
              setIsAdding(false);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setIsAdding(false);
          },
        },
      );
    }

    if (actions.type === 'jobTypes') {
      await saveJobType.mutateAsync(
        {
          type: newItem,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('jobTypes');
              toast.success('Job type added successfully');
              setNewItem('');
              setIsAdding(false);
            } else {
              setIsAdding(false);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setIsAdding(false);
          },
        },
      );
    }

    if (actions.type === 'teams') {
      await saveJobTeam.mutateAsync(
        {
          name: newItem,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('teams');
              toast.success('Team added successfully');
              setNewItem('');
              setIsAdding(false);
            } else {
              setIsAdding(false);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setIsAdding(false);
          },
        },
      );
    }

    if (actions.type === 'locations') {
      await saveJobLocation.mutateAsync(
        {
          name: newItem,
        },
        {
          onSuccess: (dt) => {
            if (dt?.success) {
              handleRefetchJobOptionItems('locations');
              toast.success('Location added successfully');
              setNewItem('');
              setIsAdding(false);
            } else {
              setIsAdding(false);
              toast.error(dt?.message || 'Something went wrong');
            }
          },

          onError: (err) => {
            toast.error(err?.message || 'Something went wrong');
            setIsAdding(false);
          },
        },
      );
    }
  };

  return (
    <div className={styles.root}>
      <DialogComponent
        open={actions.open}
        onClose={closeModal}
        title={`Manage ${actions.name}`}
        actions={
          <div className={styles.addBtn}>
            <DefaultButton
              color="primary"
              onClick={() => {
                scrollToSpecificSectionById('add-new-item');
                setIsAdding(true);
              }}
            >
              Add New
            </DefaultButton>
          </div>
        }
      >
        <div ref={dialogBoxRef}>
          <List>
            {data.map((item, index) => (
              <ListItem key={item.value}>
                {editingId === item.value ? (
                  <TextField
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    fullWidth
                    sx={{ width: '80%' }}
                  />
                ) : (
                  <ListItemText
                    primary={`${index + 1}. ${item.label}`}
                    className={styles.listItemText}
                  />
                )}
                <ListItemSecondaryAction className={styles.listItemActions}>
                  {editingId === item.value ? (
                    <div onClick={() => handleSave(item.value)} className={styles.actionItem}>
                      Save
                    </div>
                  ) : (
                    <div
                      onClick={() => handleEdit(item.value, item.label)}
                      className={styles.actionItem}
                    >
                      Edit
                    </div>
                  )}
                  <IconButton onClick={() => handleDelete(item.value)} sx={{ color: '#ff8a4a' }}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
                <Divider variant="inset" component="li" />
              </ListItem>
            ))}
            {isAdding && (
              <ListItem id="add-new-item">
                <TextField
                  value={newItem}
                  onChange={(e) => {
                    setNewItem(e.target.value);
                  }}
                  fullWidth
                  placeholder="Enter new item"
                />
                <div onClick={handleAdd} className={styles.actionItemAdd}>
                  Add
                </div>
              </ListItem>
            )}
          </List>
        </div>
      </DialogComponent>
    </div>
  );
};

export default ManageAdminFieldsModal;
