import { ApiResponse, SpeakUpInterface } from '@interfaces/index';
import apiClient from './configs/httpHandler';

export const speakUpApiHandler = {
  getSpeakUpResponses: async (speakUpId: number): Promise<ApiResponse<SpeakUpInterface>> =>
    apiClient.get(`/get-speak-ups-responses/${speakUpId}`),

  saveInstantHelpSpeakUpResponse: async (
    speakUpData: Partial<SaveSpeakUp>,
  ): Promise<ApiResponse<SaveSpeakUpResponseData>> =>
    apiClient.post('/instant-help-speak-up', speakUpData),

  saveSpeakUpConversationResponse: async (
    speakUpData: Partial<SaveSpeakUpConversation>,
  ): Promise<ApiResponse<SaveSpeakUpConversationResponseData>> =>
    apiClient.post('/create-speak-ups-response', speakUpData),

  setSpeakUpResponsesSeen: async (
    speakUpData: Partial<SetSpeakUpSeenInterface>,
  ): Promise<ApiResponse<SaveSpeakUpConversationResponseData>> =>
    apiClient.put('/speak-ups-responses-seen', speakUpData),

  archiveSpeakUpResponse: async (
    speakUpData: Partial<{ speakUpId: number }>,
  ): Promise<ApiResponse<ArchiveSpeakUpResponseResponseData>> =>
    apiClient.put('/speak-ups-archived', speakUpData),

  getUserSpeakUps: async (
    speakUpData: Partial<{
      responded: boolean | null;
      assessmentId: number | null;
      questionnaireId: number | null;
      archived: boolean | null;
    }>,
  ): Promise<ApiResponse<UserSpeakUpResponseData[]>> =>
    apiClient.post('/user-speak-ups', speakUpData),
};

export interface SaveSpeakUp {
  assessmentId: number | null;
  message: string;
  shareMyIdentity: boolean;
  questionnaireId: number;
}

interface SaveSpeakUpResponseData {
  userId: number;
  assessmentId: number | null;
  message: string;
  shareMyIdentity: boolean;
  questionnaireId: number;
  responded: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface SaveSpeakUpConversation {
  speakUpId: number;
  message: string | null;
  respondedAs: 'user' | 'admin';
  shareMyIdentity: boolean | null;
}

interface SaveSpeakUpConversationResponseData {
  instantHelpSpeakUpId: number;
  userId: number;
  message: string;
  seen: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface SetSpeakUpSeenInterface {
  speakUpId: number;
  updateAs: 'user' | 'admin';
}

interface ArchiveSpeakUpResponseResponseData {
  id: number;
  userId: number;
  assessmentId: string;
  questionnaireId: boolean;
  message: number;
  shareMyIdentity: string;
  responded: boolean;
  archived: number;
  createdAt: string;
  updatedAt: string;
}

interface SpeakUpResponse {
  responseId: number;
  responseMessage: string;
  responseSeen: boolean;
  responseCreated: string; // ISO date string
}

export interface UserSpeakUpResponseData {
  questionnaireId: number;
  assessmentId: number;
  questionnaire: string;
  speakUpId: number;
  speakUpMessage: string;
  shareIdentity: boolean;
  responded: boolean;
  speakUpCreatedDate: string; // ISO date string
  speakUpUpdatedDate: string; // ISO date string
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  archived: boolean;
  response: SpeakUpResponse;
}
