import React from 'react';
import { Checkbox, FormControlLabel, CheckboxProps } from '@mui/material';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

interface CustomCheckboxProps extends Omit<CheckboxProps, 'onChange'> {
  label: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  uncheckedIcon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
}

const IconCheckBox: React.FC<CustomCheckboxProps> = ({
  label,
  isChecked,
  onChange,
  uncheckedIcon = <RadioButtonUncheckedRoundedIcon />,
  checkedIcon = <CheckCircleOutlineRoundedIcon />,
  sx,
  ...checkboxProps
}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        icon={uncheckedIcon}
        checkedIcon={checkedIcon}
        sx={{
          color: '#019df2',
          '&.Mui-checked': {
            color: '#04bc00',
          },
          ...sx,
        }}
        {...checkboxProps}
      />
    }
    label={label}
  />
);

IconCheckBox.defaultProps = {
  uncheckedIcon: <RadioButtonUncheckedRoundedIcon />,
  checkedIcon: <CheckCircleOutlineRoundedIcon />,
};

export default IconCheckBox;
