import { getToken } from '@api/config';
import { ApiResponse, ErrorDetail } from '@interfaces/index';
import { DeepDiveQuestionnairePlan, ImprovePlan, UserImprovePlanActions } from 'custom.d';
import { createHeaders } from './httpHeaderConfig';

export const createImprovePlan = async (
  createdType: string,
  questionId: number | null,
  questionerId: number | null,
  focusAreaIds: number[] | null,
  assessmentId: number | null,
  participantId?: number | null,
) => {
  const participantIdToSend = participantId || null;
  const formattedReq = {
    createdType,
    questionId,
    questionerId,
    focusAreaIds,
    assessmentId,
    participantId: participantIdToSend,
  };

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/create-improve-plan`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;

    if (response.status === 401) {
      return 401;
    }

    const error = new Error(
      errorMessage || `Failed to create action plan: ${response?.statusText}`,
    );

    throw error;
  }

  const responseData = await response.json();

  return responseData;
};

export const getUserImprovePlans = async (active: boolean | null) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-improve-plans?active=${active}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;

    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch User improve plans: ${errorMessage}`);
  }

  const result: { userImprovePlans: UserImprovePlanActions[] } = await response.json();

  return result;
};

export const getUserImprovePlanActionsById = async (id: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-improve-plan-actions/${id}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;

    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch imrpove plans: ${errorMessage}`);
  }

  const result: ImprovePlan = await response.json();

  return result;
};

export const setActionDueDate = async (req: {
  improvePlanId: number;
  title: string;
  description: string;
  dueDate: any;
}) => {
  const formattedReq = {
    improvePlanId: req.improvePlanId,
    title: req.title,
    description: req.description,
    dueDate: req.dueDate,
  };

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/create-action-plan`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      return 401;
    }

    const error = new Error(`Failed to create action plan: ${errorMessage}`);
    throw error;
  }

  const responseData = await response.json();

  return responseData.currentActionPlan;
};

export const createUserAction = async (req: {
  userId: number;
  improvePlanId: number;
  title: string;
  description: string | null;
  dueDate: string | null;
  actionButton?: string | null;
  url?: string | null;
}) => {
  const formattedReq = {
    userId: req.userId,
    improvePlanId: req.improvePlanId,
    title: req.title,
    description: req.description,
    dueDate: req.dueDate,
    actionButton: req.actionButton || null,
    url: req.url || null,
  };

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/add-user-action`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      return 401;
    }

    const error = new Error(`Failed to create user action plan: ${errorMessage}`);
    throw error;
  }

  const responseData = await response.json();

  return responseData.currentActionPlan;
};

export const updateActionPlan = async (req: {
  id: number;
  actionType: string;
  dueDate: string | null;
  done: boolean | null;
  removed: boolean | null;
  start: boolean | null;
}) => {
  const formattedReq = {
    id: req.id,
    actionType: req.actionType,
    dueDate: req.dueDate,
    done: req.done,
    removed: req.removed,
    start: req.start,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/update-improve-plans-action`,
    {
      method: 'PUT',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    if (response.status === 401) {
      return 401;
    }

    const errorResponse = await response.json();
    const errorMessage = errorResponse?.responseObject[0]?.message;
    if (errorMessage) {
      throw new Error(errorMessage);
    }

    throw new Error('Unknown error occurred');
  }

  const responseData = await response.json();

  return responseData;
};

export const getUserReflections = async (req: {
  startDate: string | null;
  endDate: string | null;
  assessmentId: number | null;
  limit: number | null;
  orderBy: string | null;
  order: string | null;
}) => {
  const formattedReq = {
    startDate: req.startDate,
    endDate: req.endDate,
    assessmentId: req.assessmentId,
    limit: req.limit,
    orderBy: req.orderBy,
    order: req.order,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/filter-user-reflections`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      return 401;
    }

    const error = new Error(`Failed to get user reflections: ${errorMessage}`);
    throw error;
  }

  const responseData = await response.json();

  return responseData;
};

export const getImproveActionsForDashboard = async (userId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-latest-due-combined-actions`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify({ userId }),
    },
  );

  const responseData:
    | ApiResponse<{ totalProgress: number; data: LatestDueImprovePlan[] }>
    | ApiResponse<ErrorDetail> = await response.json();

  return responseData;
};

export const getDeepDives = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/short-deep-dives-latest-scores`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch deep dive data: ${errorMessage}`);
  }

  const result: DeepDive[] = await response.json();

  return result;
};

export const updateImprovePlan = async (req: { planId: number; done: boolean }) => {
  const formattedReq = {
    done: req.done,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/update-improve-plan/${req.planId}`,
    {
      method: 'PUT',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      return 401;
    }

    const error = new Error(`Failed to update improve plan: ${errorMessage}`);
    throw error;
  }

  const responseData = await response.json();

  return responseData;
};

export const getUserImprovePlansByDeepDive = async (id: number, active = true) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-user-improve-plan-by-deep-dive-id/${id}?active=${active}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch deep dive data: ${errorMessage}`);
  }

  const result: DeepDiveQuestionnairePlan[] = await response.json();

  return result;
};

export const updateActionsByImprovePlanId = async (
  planId: number,
  actionType: string,
  done: boolean,
) => {
  const formattedReq = {
    id: planId,
    actionType,
    done,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/update-improve-plans-action-by-planId`,
    {
      method: 'PUT',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    if (response.status === 401) {
      return 401;
    }
  }

  const responseData = await response.json();

  return responseData;
};

export const completePilar = async (req: {
  questionnaireId: number;
  improvePlanId: number | null;
}) => {
  const formattedReq = {
    questionnaireId: req.questionnaireId,
    improvePlanId: req.improvePlanId,
  };

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/complete-pilar`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  const responseData = await response.json();

  return responseData;
};

interface DeepDive {
  questionId: number;
  questionText: string;
  colorTheme: string;
  deepDiveId: number;
  latestAssessmentScore: number;
  icon: string;
  deepDiveName: string;
}

interface ActionDetails {
  actionId: number;
  actionType: string;
  actionTitle: string;
  actionDescription: string | null;
  actionImage: string | null;
}

interface Action {
  improvePlanId: number;
  done: boolean;
  dueDate: string;
  improvePlanActionDueDays: number;
  removed: boolean;
  startAt: string | null;
  completedAt: string | null;
  details: ActionDetails;
}

export interface LatestDueImprovePlan {
  id: number;
  userId: number;
  questionId: number;
  improvePlan: string;
  questionColor: string;
  questionIcon: string;
  progress: number;
  done: boolean;
  actions: Action[];
}
