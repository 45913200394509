import blueTray from '@assets/images/icons/blueTray.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import IconCheckBox from '@components/Atoms/IconCheckBox';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import {
  useSaveSpeakUpConversation,
  useSetSpeakUpResponsesSeen,
  useSpeakUpResponses,
} from '@hooks/api/useSpeakUp';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { SpeakUpInterface } from '@interfaces/index';
import SendIcon from '@mui/icons-material/Send';
import { CircularProgress, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { SPEAK_UP_MESSAGING_ERRORS } from 'custom.d';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { refreshConfigurationSliceActions } from 'store/Slices/RefreshCofigurationsSlice';
import styles from './index.module.scss';

const InstantThoughMessaging: React.FunctionComponent<InstantThoughMessagingProps> = (props) => {
  const { title, isOpen, onClose, speakUpId, isUserInterface } = props;
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [messageText, setMessageText] = useState('');
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const [conversationHistory, setConversationHistory] = useState<SpeakUpInterface | null>(null);
  const saveSpeakUpMutation = useSaveSpeakUpConversation();
  const setSeenSpeakUpResponseMutation = useSetSpeakUpResponsesSeen();
  const [errorMessage, setErrorMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isUserSharedIdentity, setIsUserSharedIdentity] = useState(false);
  const [isResponseLimitExceeded, setIsResponseLimitExceeded] = useState(false);
  const dispatch = useAppDispatch();

  const {
    data: conversationData,
    error,
    isLoading: conversationLoading,
  } = useSpeakUpResponses(speakUpId, isOpen);

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    if (chatBox) {
      setTimeout(() => {
        chatBox.scrollTo({
          top: chatBox.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [messageText, isOpen, conversationData]);

  useEffect(() => {
    if (isOpen) {
      dispatch(refreshConfigurationSliceActions.resetConfigurations());
      setSeenSpeakUpResponseMutation.mutateAsync({
        speakUpId,
        updateAs: isUserInterface ? 'user' : 'admin',
      });
      setMessageSent(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (conversationData?.success) {
      setConversationHistory(conversationData?.responseObject);
      setIsUserSharedIdentity(conversationData?.responseObject?.shareMyIdentity || false);
    } else {
      setConversationHistory(null);
    }
  }, [conversationData, conversationLoading, isOpen]);

  const handleSendMessage = async () => {
    if (messageText.trim() === '') return;
    setSendingMessage(true);

    if (conversationHistory) {
      const newMessage = {
        firstName: conversationHistory?.speakUpResponses[0]?.firstName || 'User',
        lastName: conversationHistory?.speakUpResponses[0]?.lastName || 'Lastname',
        message: messageText,
        createdAt: new Date().toISOString(),
        userType: isUserInterface ? 'user' : 'admin',
      };

      setConversationHistory({
        ...conversationHistory,
        speakUpResponses: [...conversationHistory.speakUpResponses, newMessage],
      });
      setMessageText('');
    }

    await saveSpeakUpMutation.mutateAsync(
      {
        speakUpId,
        message: messageText,
        respondedAs: isUserInterface ? 'user' : 'admin',
        shareMyIdentity: isUserInterface ? isChecked : null,
      },
      {
        onSuccess: (dt) => {
          if (dt?.success) {
            setErrorMessage('');
            setSendingMessage(false);
            setMessageSent(true);
            setIsResponseLimitExceeded(false);
            if (dt?.responseObject && isChecked) {
              setIsUserSharedIdentity(isChecked);
              setIsChecked(false);
            }
          } else {
            if (
              dt?.message?.toLowerCase() ===
              SPEAK_UP_MESSAGING_ERRORS.YOU_HAVE_EXCEEDED_YOUR_RESPONSE_LIMIT
            ) {
              setIsResponseLimitExceeded(true);
              toast.error(dt?.message || 'An unexpected error occurred. Try again later');
            } else {
              setErrorMessage('Message failed, Try again.');
            }

            setSendingMessage(false);
          }
        },

        onError: (err) => {
          if (
            err?.message?.toLowerCase() ===
            SPEAK_UP_MESSAGING_ERRORS.YOU_HAVE_EXCEEDED_YOUR_RESPONSE_LIMIT
          ) {
            setIsResponseLimitExceeded(true);
          } else {
            setErrorMessage('Message failed, Try again.');
          }

          setSendingMessage(false);
        },
      },
    );
  };

  const formatDateTime = (isoString: string) => {
    const date = new Date(isoString);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? `0${minutes}` : minutes} ${
      hours >= 12 ? 'PM' : 'AM'
    }`;

    const formattedDate = `${date.toLocaleDateString()}`;

    return { formattedTime, formattedDate };
  };

  const closeMessageModal = () => {
    setMessageText('');
    setConversationHistory(null);
    onClose();
    setMessageText('');
    setErrorMessage('');
    setSendingMessage(false);
    setIsChecked(false);
    setIsResponseLimitExceeded(false);
    dispatch(refreshConfigurationSliceActions.setRefreshSpeakUps(true));
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  return (
    <div className={styles.root}>
      <Modal
        open={isOpen}
        setOpen={closeMessageModal}
        overlay
        className={`${styles.modalRoot} ${
          conversationData?.responseObject?.shareMyIdentity && !isChecked ? styles.modalRootMod : ''
        }`}
      >
        <DefaultButton
          color="primary"
          type="button"
          className={styles.btnClose}
          onClick={() => {
            if (sendingMessage) {
              toast.info('Please wait until the previous message has been sent.');
            } else {
              closeMessageModal();
            }
          }}
          disabled={sendingMessage}
        >
          <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
        </DefaultButton>

        {isResponseLimitExceeded ? (
          <div className={styles.modalBody}>
            <div className={styles.modalTitle}>{title}</div>

            <hr />

            <div className={styles.responseLimitExceededContainer}>
              <p>
                You have sent high number of speak up messages recently. Please wait for the
                administrator to respond to you to be able to follow up. If you need urgent help,
                please{' '}
                <span
                  onClick={() => {
                    closeMessageModal();
                    history.push('/wellbeing');
                  }}
                >
                  click here
                </span>{' '}
                to access urgent help resources.
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.modalBody}>
            <div className={styles.modalTitle}>{title}</div>

            <hr />

            <div className={styles.modalBody}>
              <div className={styles.chatContainer} ref={chatBoxRef}>
                {conversationLoading ? (
                  <>
                    <div className={classNames(styles.messageBubble, styles.userBubble)}>
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                    </div>

                    <div className={classNames(styles.messageBubble, styles.systemBubble)}>
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                      <Skeleton variant="text" className={styles.loadingText} height={20} />
                    </div>
                  </>
                ) : !conversationHistory ? (
                  <div className={styles.noDataSection}>
                    <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                    <p>No messages to preview</p>
                  </div>
                ) : (
                  conversationHistory &&
                  conversationHistory?.speakUpResponses.map((message, index) => {
                    const { formattedTime, formattedDate } = formatDateTime(message.createdAt);
                    const isLastMessage =
                      conversationHistory?.speakUpResponses?.length &&
                      index === conversationHistory.speakUpResponses.length - 1;

                    return (
                      <>
                        {isUserInterface ? (
                          <div
                            key={index}
                            className={classNames(
                              styles.messageBubble,
                              message.userType === 'user' ? styles.userBubble : styles.systemBubble,
                            )}
                          >
                            <div>
                              {message.userType === 'user' ? (
                                <>
                                  <strong>You:&nbsp;&nbsp;</strong>
                                  {message.message}
                                </>
                              ) : (
                                <>
                                  <strong>Administrator:&nbsp;&nbsp;</strong>
                                  {message.message}
                                </>
                              )}
                            </div>
                            {errorMessage && errorMessage?.length > 0 && isLastMessage ? (
                              <div className={styles.errorInTimestamp}>{errorMessage}</div>
                            ) : sendingMessage && isLastMessage ? (
                              <div className={styles.sendingTimestamp}>
                                <CircularProgress size={10} color="inherit" />
                                <span> sending...</span>
                              </div>
                            ) : (
                              <div
                                className={
                                  message.userType === 'user'
                                    ? styles.timeStampUser
                                    : styles.timeStampSystem
                                }
                              >
                                {formattedTime} · {formattedDate}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            key={index}
                            className={classNames(
                              styles.messageBubble,
                              message.userType === 'admin'
                                ? styles.userBubble
                                : styles.systemBubble,
                            )}
                          >
                            <div>
                              {message.userType === 'admin' ? (
                                <>
                                  <strong>You:&nbsp;&nbsp;</strong>
                                  {message.message}
                                </>
                              ) : (
                                <>
                                  <strong>
                                    {conversationHistory?.shareMyIdentity
                                      ? conversationHistory?.speakUpResponses[0]?.firstName &&
                                        conversationHistory?.speakUpResponses[0]?.lastName
                                        ? `${conversationHistory?.speakUpResponses[0]?.firstName} ${conversationHistory?.speakUpResponses[0]?.lastName}`
                                        : 'User'
                                      : 'User'}
                                    :&nbsp;&nbsp;
                                  </strong>
                                  {message.message}
                                </>
                              )}
                            </div>
                            {errorMessage && errorMessage?.length > 0 && isLastMessage ? (
                              <div className={styles.errorInTimestamp}>{errorMessage}</div>
                            ) : sendingMessage && isLastMessage ? (
                              <div className={styles.sendingTimestamp}>
                                <CircularProgress size={10} color="inherit" />
                                <span> sending...</span>
                              </div>
                            ) : (
                              <div
                                className={
                                  message.userType === 'admin'
                                    ? styles.timeStampUser
                                    : styles.timeStampSystem
                                }
                              >
                                {formattedTime} · {formattedDate}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })
                )}
              </div>
            </div>
            {conversationHistory && (
              <>
                <div className={styles.messageInputArea}>
                  <div className={styles.shareMyIdentityInner}>
                    {conversationData &&
                      !conversationData?.responseObject?.shareMyIdentity &&
                      !isUserSharedIdentity &&
                      isUserInterface && (
                        <IconCheckBox
                          label="Share my identity"
                          isChecked={isChecked}
                          onChange={handleCheckboxChange}
                          disabled={sendingMessage}
                        />
                      )}

                    {isChecked && (
                      <p>Your name and email address will be shared alongside your thoughts.</p>
                    )}
                  </div>

                  <div className={styles.messageInputContainer}>
                    <input
                      className={styles.messageInput}
                      type="text"
                      placeholder="Type a message"
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                      disabled={conversationLoading}
                      onClick={() => {
                        if (sendingMessage) {
                          toast.info('Please wait until the previous message has been sent.');
                        }
                      }}
                    />
                    <DefaultButton
                      className={styles.sendButton}
                      onClick={handleSendMessage}
                      disabled={messageText?.length === 0 || conversationLoading || sendingMessage}
                    >
                      <SendIcon className={styles.sendIcon} />
                    </DefaultButton>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

interface InstantThoughMessagingProps {
  title: string;
  isOpen: boolean;
  onClose: () => void | undefined;
  speakUpId: number;
  isUserInterface: boolean;
}

export default InstantThoughMessaging;
