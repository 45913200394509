import ChatIcon from '@assets/images/earlyWarning/chat-icon.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { useAppSelector } from '@hooks/useAppSelector';
import React, { useState } from 'react';
import { RootState } from 'store';
import CreateSpeakUp from '../CreateSpeakUp';
import styles from './index.module.scss';

const SpeakUpPortal: React.FunctionComponent<ThoughtsResponsesProps> = ({ loading }) => {
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig.data);
  const createSpeakUpEnabled = companyConfigData?.showSpeakUp;
  const [createSpeakUpOpen, setCreateSpeakUpOpen] = useState(false);

  return (
    <>
      {createSpeakUpOpen && (
        <CreateSpeakUp
          createSpeakUpOpen
          handleSpeakUpModalOpen={() => setCreateSpeakUpOpen(false)}
        />
      )}

      {createSpeakUpEnabled && (
        <div className={styles.speakUpCard}>
          <div className={styles.speakUpContent}>
            <div className={styles.leftSection}>
              <div className={styles.top}>
                <Img src={ChatIcon} alt="Chat Icon" className={styles.chatIcon} />
                <div className={styles.title}>Speak Up</div>
              </div>

              <p className={styles.description}>
                If you feel up to it, why not express your thoughts and feelings with your
                organisation. They’re here to help.
              </p>
            </div>
            <div className={styles.rightSection}>
              <DefaultButton onClick={() => setCreateSpeakUpOpen(true)}>Speak Up</DefaultButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SpeakUpPortal.defaultProps = {
  loading: false,
};

interface ThoughtsResponsesProps {
  loading?: boolean;
}

export default SpeakUpPortal;
